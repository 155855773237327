import React from 'react'
import { colors } from '@dqp/common/styles/utilities'
import NotFound from '#containers/NotFound/NotFound'
import SEO from '#components/SEO'
import PublicLayout from '#components/PublicLayout'
import heroimage from '#images/hero/notfound_hero.png'

const NotFoundPage = () => (
  <PublicLayout headerColor={colors.red}>
    <SEO
      title='404: Not found'
      meta={[{ name: 'theme-color', content: colors.red }]}
      image={heroimage}
    />
    <NotFound />
  </PublicLayout>
)

export default NotFoundPage
